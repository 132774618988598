require('dotenv').config();

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

const alchemyKey       = process.env.REACT_APP_ALCHEMY_URL;
const contractAddress  = process.env.REACT_APP_CONTRACT_ADDRESS;

const TOTAL_PISS_PUNKS = 9999;
let   PISS_PUNK_PRICE  = -1;

const web3             = createAlchemyWeb3(alchemyKey);
const contract         = require("./contracts/PissPunks.json");
const nftContract      = new web3.eth.Contract(contract.abi, contractAddress);

// fetch piss punk price
export const fetchPissPunkPrice = () => {
    return new Promise(resolve => {
        PISS_PUNK_PRICE < 0 ?
            nftContract.methods.getPissPunkPrice().call(
                (err, res) =>
                (err ? resolve(-1) : resolve(res))
            ) :
            resolve(PISS_PUNK_PRICE)
    });
};

export const checkIsSaleActive = () => {
    return new Promise(resolve => {
        nftContract.methods.mintEnabled().call(
            (err, res) =>
            (err ? resolve(false) : resolve(res))
        )
    });
};

const fetchReserveLeft = () => {
    return new Promise(resolve => {
        nftContract.methods.getReserveLeft().call(
            (err, res) => (err ? resolve(0) : resolve(res))
        )
    });
};

const fetchTotalSupply = () => {
    return new Promise(resolve => {
        nftContract.methods.totalSupply().call(
            (err, res) => (err ? resolve(0) : resolve(res))
        )
    });
};

export const fetchPissPunksMintCount = async () => {
    const [reserveLeft, totalSupply] = await Promise.all([
        fetchReserveLeft(), fetchTotalSupply()
    ]);

    return reserveLeft + totalSupply;
};

export function catchEm(promise) {
    return promise.then(data => [null, data])
        .catch(err => [err]);
}

export const mintPissPunks = async(amount, value) => {
    console.log('will call mint with ', amount, value);

    //error handling
    if (amount < 1 || amount > 25) {
        return {
            success: false,
            status: "❗ You can only mint between 1 and 25 PissPunks",
        }
    }

    const method = nftContract.methods.mint(amount);
    let gasEstimate = 0;

    const [error, gas] = await catchEm(method.estimateGas({
        from: window.ethereum.selectedAddress,
        value: value
    }));

    if (error) {
        return {
            success: false,
            status: "😥 " + error
        }
    }   else {
        gasEstimate = gas;
    }

    console.log('my gas estimate is ', gasEstimate);

    try {
        //set up your Ethereum transaction
        const transactionParameters = {
            to: contractAddress,
            from: window.ethereum.selectedAddress,
            value: value.toString(16),
            'gas': parseInt(gasEstimate*1.1).toString(16),
            'data': method.encodeABI()
        };

        //sign the transaction via Metamask
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan:\n https://etherscan.io/tx/" + txHash + "</a>"
        }
    }   catch (error) {
        const message = (typeof error === 'string' || error instanceof String) ? error :
            ((typeof error === "object" || error instanceof Object) && error.hasOwnProperty("message") ? error.message : error)
        return {
            success: false,
            status: "😥 " + message
        }
    }   finally {
    }
};
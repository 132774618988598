import { Component } from "react";
import { useMetaMask } from "metamask-react";
import LoadingSpinner from "./LoadingSpinner";
import { fetchPissPunkPrice, mintPissPunks, checkIsSaleActive, fetchPissPunksMintCount } from "./contract.js";


class Minter extends Component {

    constructor() {
        super();
        this.state = {
            price: -1,
            isSaleActive: false,
            feedback: '',
            punksTotal: 9999,
        };
    }

    componentDidMount () {
        fetchPissPunkPrice()
            .then(price => this.setState({price: price}))
            .catch(e => (0));

        checkIsSaleActive()
            .then(isActive => this.setState({isSaleActive: isActive}))
            .catch(e => (0));

        fetchPissPunksMintCount()
            .then(count => this.setState({punksLeft: count}))
            .catch(e => (0));
    }

    mintPunks = (amount) => {
        this.setState({feedback: ''});
        const {price} = this.state;

        try {
            mintPissPunks(amount, amount * price)
                .then(response => this.setState({feedback: response.status}))
                .catch(e => this.setState({feedback: e}));
        }   catch (e) {
        }
    };

    render() {
        const {feedback, punksLeft} = this.state;

        return (
            <div className="Minter">
                <p className="title">
                    Mint
                    <span className="subtitle">
                        0 / 9999 left
                    </span>
                </p>

                <img src="ppgrid.png"  alt="PissPunks"/>

                <p className="description">
                    All Piss Punks have been minted. For secondary, go to<br/>
                    <a target="_blank" href="https://opensea.io/collection/pisspunks">Piss Punks on Opensea</a>
                    {/*<MintSwitch mint={this.mintPunks}
                                price={this.state.price}
                                isActive={this.state.isSaleActive}
                                feedback={this.state.feedback}
                    />*/}
                </p>
                <p className="description">
                    Follow the story of Maxon Bollocks on Twitter: &nbsp;

                    <a target="_blank" rel="noreferrer" href="https://www.twitter.com/MaxonBollocks">
                        <img alt="twitter" src="twitter.svg" />
                        MaxonBollocks
                    </a>
                </p>
            </div>
        );
    }
}

const MintSwitch = ({mint, price, isActive, feedback}) => {
    const { status, connect } = useMetaMask();

    const mintPage = () => {
        if (isActive) {
            // const amounts = [1, 5, 10, 20, 25];
            const amounts = [];

            return <ul>
                {amounts.map((amount, index) =>
                    (<li key={index}>
                        <span className="mint-title">
                            {amount} PissPunk{amount > 1 ? 's' : ''}
                        </span>
                            {
                                (parseInt(price)===1) ? "0.00 ETH " : (amount * price / 1e18) + " ETH "
                            }+ GAS<br/><br/><br/><br/>
                            <button onClick={() => (mint(amount))} className="mintButton">
                                Mint
                            </button>
                        </li>
                    ))
                }
            </ul>
        }   else {
            return <p className="description">
                <b>Sorry, the sale is not yet active. Please try again later.</b>
                <br/><br/>
            </p>
        }
    };

    switch (status) {
        case 'unavailable':
            return <p className="install-metamask">You haven't installed MetaMask. Please go to the official
                MetaMask website to download it.</p>;
        case 'notConnected':
            return <>
                <button onClick={connect}
                        className="submit-button">Connect to MetaMask
                </button>
            </>;
        case 'connecting':
        case 'initializing':
            return <LoadingSpinner className="overlay"/>;

        case 'connected':
            return <div>
                <img src="ppgrid.png"  alt="PissPunks"/>
                <div className="description">
                    Current price is minimum amount allowed in contract (0.000000000000000001 ETH) per punk + GAS
                </div>
                {   feedback &&
                <div className="feedback">
                    {feedback}
                </div>
                }
                {
                    mintPage()
                }
            </div>;

        default:
            return <div />;
    }
};

export default Minter;
